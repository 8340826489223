import React from "react"
import PropTypes from "prop-types"
import {useLocation} from "@reach/router"
import {useStaticQuery, graphql} from "gatsby"
import {GatsbySeo} from "gatsby-plugin-next-seo";

const SEO = ({seoData}) => {
  const {pathname} = useLocation()
  const {site} = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    siteUrl,
    defaultSiteName
  } = site.siteMetadata

  const seo = {
    title: seoData.title || defaultTitle,
    description: seoData.opengraphDescription || defaultDescription,
    image: seoData.opengraphImage?.sourceUrl || defaultImage,
    keywords: seoData.metaKeywords,
    url: `${siteUrl}${pathname}` || siteUrl,
    noindex: seoData.metaRobotsNoindex === 'noindex',
    nofollow: seoData.metaRobotsNofollow === 'nofollow',
    siteName: seoData.opengraphSiteName || defaultSiteName,
  }

  return (
    <>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        canonical={seo.url}
        noindex={seo.noindex}
        nofollow={seo.nofollow}
        keywords={seo.keywords}

        openGraph={{
          title: seo.title,
          description: seo.description,
          url: seo.url,
          site_name: seo.siteName,
          images: [
            { url: seo.image }
          ]
        }}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl: url
                defaultSiteName: siteName
            }
        }
    }
`
